import ExcelJS from 'exceljs';
import docx4js from 'docx4js';
export class NaviCode {
  // type: string
  // title: string
  // langs: string[] = []
  // infos: CodeInfo[] = []
  // points: NaviPoint[] = []
  // errors: string[] = []

  constructor() {
    this.type = '';
    this.title = '';
    this.langs = [];
    this.infos = [];
    this.points = [];
    this.errors = [];
  }

  parseType(rowValues) {
    this.type = parseValue(rowValues['2']);
    if (isNaN(this.type)) {
      this.errors.push('Navi Type should be number');
    }
    return this;
  }

  parseTitle(rowValues) {
    let index = 1;
    // const value = parseValue(rowValues[`${index}`])

    // if (value !== 'ナビコードファイル名') {
    //   this.errors.push('Title format is invalid')
    // }

    // lang will start from column B
    index = 2;
    for (let i = 0; i < this.langs.length; i++) {
      let text = parseValue(rowValues[`${index + i}`]);
      const codeInfo = {
        text: text,
        lang: this.langs[i]
      };

      if (text === '') {
        this.errors.push('Code name must not be empty');
      }

      this.infos.push(codeInfo);
    }
    return this;
  }

  parseLangs(rowValues) {
    // const value = parseValue(rowValues['1'])

    const length = rowValues.length;
    for (let index = 2; index < length; index++) {
      const lang = rowValues[`${index.toString()}`];
      if (lang) {
        this.langs.push(lang);
      } else if (index === 2) {
        this.errors.push('1st language must not be emtpy');
      }
    }

    return this;
  }

  parsePoint(rowValues) {
    const isNaviType = +this.type === 1;
    const langCount = this.langs.length;

    const point = {
      lat: '',
      long: '',
      categories: [],
      infos: [],
      error: ''
    };

    let startName = Math.max(4, 1 + langCount + 1); // 1 is first column
    if (isNaviType) startName += 1; // ignore ポイント連番

    let startInfo = startName + this.langs.length + 2; // 2 is lat, long
    if (isNaviType) {
      startInfo += 1; // ignore ビーコンID
    } else {
      startInfo += NUM_CATEGORIES; // ignore categories
    }
    let isEmptyName = true;
    let isEmptyInfo = true;
    let isEmptyGeo = true;
    let isEmptyCategory = true;
    let lstError = [];

    for (let i = 0; i < this.langs.length; i++) {
      let text = parseValue(rowValues[`${startName + i}`]);
      let info = parseValue(rowValues[`${startInfo + i}`]);

      if (!text) {
        lstError = [...lstError, 'Point name must not be empty'];
      } else {
        isEmptyName = false;
      }

      if (info) {
        isEmptyInfo = false;
      }

      const pointInfo = {
        text: text,
        info: info,
        lang: this.langs[i]
      };

      point.infos.push(pointInfo);
    }

    const startGeo = startName + this.langs.length;
    point.lat = parseValue(rowValues[`${startGeo}`]);
    point.long = parseValue(rowValues[`${startGeo + 1}`]);

    if (point.lat === '' || point.long === '') {
      console.log('');
    } else if (point.lat === '' || point.long === '') {
      lstError = [...lstError, '(lat,lon) must not be emtpy'];
      isEmptyGeo = false;
    } else if (isNaN(point.lat) || isNaN(point.long)) {
      lstError = [...lstError, '(lat,lon) must be number'];
      isEmptyGeo = false;
    } else {
      isEmptyGeo = false;
    }

    // categories
    if (!isNaviType) {
      const startCategory = startName + this.langs.length + 2;
      for (let i = startCategory; i < startCategory + 6; i++) {
        let category = parseValue(rowValues[`${i}`]);

        if (category === '' && i === startCategory) {
          lstError = [...lstError, '1st category must not be empty'];
        } else if (isNaN(category)) {
          lstError = [...lstError, 'Category must be number'];
          isEmptyCategory = false;
        } else if (category) {
          point.categories.push(category);
          isEmptyCategory = false;
        }
      }
    }
    if (!isEmptyName || !isEmptyGeo || !isEmptyCategory || !isEmptyInfo) {
      this.errors = [...this.errors, ...lstError];
      this.points = [...this.points, point];
    }

    return this;
  }
}

const MARK_START_NEW_CODE = 'Navi Type';
const NUM_CATEGORIES = 6;

const parseValue = value => {
  if (typeof value === 'object' && value.richText) {
    return value.richText.map(rich => rich.text).join('');
  }
  return value ? value.toString() : '';
};

/**
 * Util class parse UV data to json
 */
export default class ExcelTransformer {
  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async parse(file) {
    const buffer = await this.readFile(file);
    const wb = new ExcelJS.Workbook();
    const workbook = await wb.xlsx.load(buffer);
    const result = {};

    workbook.eachSheet(sheet => {
      let code = new NaviCode();
      const fileName = file.name.split('.')[0] + '_' + sheet.name;
      result[fileName] = [];
      let index = 1;
      // [[sheet1]]

      for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
        const row = sheet.getRow(rowIndex);
        const rowValues = row.values;
        if (!rowValues) return rowIndex;

        const type = parseValue(rowValues['1']);
        if (rowIndex === 1 && type !== MARK_START_NEW_CODE) {
          return rowIndex;
        }

        if (type === MARK_START_NEW_CODE) {
          // start a new code
          code = new NaviCode();
          index = 1;
          result[fileName].push(code);
        }

        // code type
        if (index === 1) {
          code.parseType(rowValues);
        }

        // language code
        if (index === 2) {
          code.parseLangs(rowValues);
        }

        // header
        if (index === 3) {
          code.parseTitle(rowValues);
        }

        // from line 4 is point info
        if (index >= 4) {
          code.parsePoint(rowValues);
        }

        // move to next row
        index++;
      }
    });

    // remove sheet which have no code
    const finalResult = Object.entries(result)
      .map(([name, codes]) => ({ name, codes }))
      .filter(({ codes }) => codes.length);

    return Promise.resolve(finalResult);
  }
}
export class DocTransformer {
  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }
  parse(file) {
    return docx4js.load(file).then(doc => {
      const title = doc?.props?.name;
      let pageNumber = 1;
      let listText = [''];
      return doc.render((type, props, children) => {
        if (type === 't') {
          listText[pageNumber - 1] += children.join('\n');
        }
        if (type === 'p') {
          listText[pageNumber - 1] += '\n';
        }
        if (type === 'lastRenderedPageBreak') {
          pageNumber++;
          listText[pageNumber - 1] = '';
        }
        return { title, listText };
      });
    });
  }
}
